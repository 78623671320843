<template>
  <div>
    <crud-editor
      ref="editor"
      :is-edit="isEdit"
      :definition="definitionEdit"
      :fetch-by-id="fetchById"
      :disabled="hasNoEditPermission"
      :save="saveData"
      :return-to="returnTo"
      :item-type="entity"
      :page-loading="loading"
      :get-dependencies="getDependencies"
      :get-layout="layout"
    />
  </div>
</template>

<script>
import CrudEditor from '@/components/crud/TabbedEditor';
import formMixin from './form-mixin';
import { hasEditPermissionForCode } from '@/utils/permission';
import { getEditLayoutDefinition } from './form.js';
import { ForceFileDownload } from '@/utils/fileDownload';
import { toString } from '@/utils/date';

export default {
  components: {
    CrudEditor
  },
  mixins: [formMixin],
  data() {
    return {
      layout: getEditLayoutDefinition,
      loading: false,
      isEdit: true
    };
  },
  computed: {
    hasNoEditPermission() {
      return !hasEditPermissionForCode(this.entity, this.$store.getters.permissions);
    }
  },
  methods: {
    fetchById(id) {
      return this.$store
        .dispatch(this.entityStore + '/getItem', { selector: { id } })
        .then(item => this.createFormDataFromItem(this, item));
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    },
    downloadCsvAfterConfirm() {
      this.$store
        .dispatch('notify/confirm', {
          message: this.$i18n.t('scratchAndWin.confirmDownloadCsv'),
          title: this.$i18n.t('common.confirm'),
          type: 'success'
        })
        .then(() => {
          const form = this.$store.getters['editor/flattenedForm'];
          if (form.exportedAt) {
            this.$store
              .dispatch('notify/confirm', { message: this.$i18n.t('scratchAndWin.confirmAlreadyExported') })
              .then(() => {
                this.doDownloadCsv();
              })
              .catch(() => {
                console.log('export cancelled by user');
              });
          } else {
            this.doDownloadCsv();
          }
        })
        .catch(() => {
          console.log('export cancelled by user');
        });
    },
    async doDownloadCsv() {
      this.loading = true;
      this.$store
        .dispatch(this.entityStore + '/getWinCodesCsv', this.$route.params.id)
        .then(blob => {
          const currentDateText = toString(new Date(), 'YYYYMMDD_HHmmss');
          const fileName = `WinCodes_${currentDateText}.csv`;
          ForceFileDownload(blob, fileName);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
